import React from "react"
import { Link } from "gatsby"
import timeAgo from "../helpers/date"

// Generated by https://quicktype.io

interface Props {
  post: Post
}

export interface Post {
  node: Node
}

export interface Node {
  id: string
  frontmatter: Frontmatter
}

export interface Frontmatter {
  title: string
  date: string
  tags: string[]
  excert: string
  path: string
}

const PostPreview = ({ post }: Props) => {
  const date = new Date(post.node.frontmatter.date)
  const formattedDate =
    date.getDate() + "." + (date.getMonth() + 1) + "." + date.getFullYear()
  return (
    <Link
      className="m-4 w-full md:w-1/3 bg-gray-800 p-5 flex-grow rounded-lg shadow-md"
      to={post.node.frontmatter.path}
    >
      <div className="">
        <h2>{post.node.frontmatter.title} </h2>
        <h4>{formattedDate} </h4>

        <p> {post.node.frontmatter.excert} </p>
      </div>
    </Link>
  )
}

export default PostPreview
